import request from '@/utils/request'

//提打印交量表参数
export function measureGbAdd(data) {
    return request({
        url: '/measureGbAdd',
        method: 'post',
        data
    })
}

//获取打印量表信息
export function measureGb() {
    return request({
        url: '/measureGb',
        method: 'post',
    })
}