<template>
  <div class="tc-evaluate_entry" style="display:flex">
    <div>
      <div class="form">
        <el-form ref="form" :model="form" label-width="130px" size="mini">
          <el-form-item label="测评用户：">
            <el-radio-group v-model="form.resource">
              <el-radio label="0">查询用户</el-radio>
              <el-radio label="1">添加用户</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="选择部门：">
            <el-select v-model="form.department_id" @change="upUser" clearable >
              <el-option
                v-for="item in departmentList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
                >{{ item.name }}</el-option
              >
            </el-select>
          </el-form-item>
          <div class="query" v-if="form.resource == 0">
            <el-form-item label="选择用户：">
              <!-- <span>请先选择部门</span> -->
              <el-select v-model="form.uId"  filterable clearable >
                <el-option
                  v-for="item in userInfo"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="addUser" v-if="form.resource == 1">
            <el-form-item label="用户编号：">
              <el-input v-model="form.account"></el-input>
            </el-form-item>
            <el-form-item label="姓名：">
              <el-input v-model="form.name"></el-input>
            </el-form-item>
            <!-- <el-form-item label="身份证号:">
              <el-input v-model="form.idcard"></el-input>
            </el-form-item> -->
            <el-form-item label="密码：">
              <el-input v-model="form.password"></el-input>
            </el-form-item>
            <el-form-item label="学历：">
              <el-select v-model="form.culture" clearable >
                <el-option
                  :label="item"
                  :value="item"
                  v-for="(item, key) in culture"
                  :key="key"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="性别："> 
              <el-radio-group v-model="form.sex">
                <el-radio label="0">男</el-radio>
                <el-radio label="1">女</el-radio>
              </el-radio-group>
            </el-form-item>
            <!-- <el-form-item label="出生日期(公历)：">
              <el-date-picker
                v-model="form.value1"
                type="date"
                placeholder="选择日期"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item> -->
            <el-form-item label="年龄" prop="ages">
              <el-input
                type="number"
                v-model="form.value1"
                maxlength="16"
                :min="0"
              ></el-input>
            </el-form-item>
          </div>
          <el-form-item label="测评时间：">
            <el-date-picker
              v-model="form.value2"
              type="date"
              placeholder="选择日期"
              value-format="timestamp"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="选择量表：">
            <el-select v-model="form.measure" @change="loadAnswer">
              <el-option
                :label="item.measure_title"
                :value="item.id"
                v-for="(item, key) in scaleList"
                :key="key"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <el-row>
        <h3>测评结果:</h3>
        <table>
          <el-row v-for="(item, key) in subject" :key="key">
            <el-col class="an-hover">
              <tr class="an-title">
                <div v-html="item.question" :index="key"></div>
              </tr>
              <template v-if="!item.isCheck">
                <tr v-for="(item2, key2) in item.options" :key="key2">
                  <el-radio
                    v-model="answerData[key]"
                    :label="key2 + `-` + item.id"
                    >{{ item2 }}</el-radio
                  >
                </tr>
              </template>
              <template v-else>
                <tr v-for="(item2, key2) in item.options" :key="key2">
                  <el-checkbox-group
                    v-model="checkList[key]"
                    :min="item.extra.checkbox.min"
                    :max="item.extra.checkbox.max"
                  >
                    <el-checkbox
                      :label="key2 + `-` + item.id"
                      :value="key2"
                      @change="handleCheckBox(key, key2)"
                      >{{ item2 }}</el-checkbox
                    >
                  </el-checkbox-group>
                </tr>
              </template>
            </el-col>
          </el-row>
        </table>
      </el-row>
      <div class="confirm-btn" @click="onSubmit">
        <img src="../../../assets/images/confirm.png" alt />
      </div>
    </div>
     <!-- <div>
      <el-form  ref="form2"  label-width="130px" size="mini" >
        <el-form-item label="量表选择：">
          <template>
            <el-select v-model="cardObj.file_name" filterable placeholder="请搜索">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </template>
        </el-form-item>
        <el-form-item label="类型选择：">
          <template>
            <el-select v-model="cardObj.type" filterable placeholder="请选择">
              <el-option
                label="通用版"
                :value="1">
              </el-option>
               <el-option
                label="标准版"
                :value="2">
              </el-option>
            </el-select>
          </template>
        </el-form-item>
        <el-form-item label="读卡数量：">
          <el-input-number v-model="cardObj.count" :min="1"></el-input-number>
        </el-form-item>
        <el-button style="margin-left:140px;background:#9BD1EF" size="mini" @click="cardReading()">确定</el-button>
      </el-form>
    </div> -->
  </div>
</template>
<script>
import axios from "axios";
import { loadDepartmentData } from "@/api/comm.js";
import { adminResultsCalculated } from "@/api/information.js";
import { getMeasureClassifyList, loadUserData } from "@/api/evaluate.js";
import { scanner } from "@/api/card_reading.js";
// 引入打印列表与提交
import { measureGbAdd,measureGb } from "@/api/print.js";
export default {
  inject: ["reload"],
  data() {
    return {
      cardObj:{
        file_name:"",
        type:"",
        count:1,
      },
      options:[],
      form: {
        resource: "0",
        value1: "",
        value2: "",
        department_id: "",
        uId: "",
        account: "",
        idcard: "",
        name: "",
        age: "",
        sex: "",
        password: "",
        culture: "",
        measure: "",
      },
      // 部门
      departmentList: [],
      // 报告类型
      evaluateClass: [],
      scaleList: [],
      // 用户数据
      userInfo: [],
      // 答题题目数据
      subject: [],
      answerData: [],
      checkList: [[]], // 多选
      culture: [
        "文盲",
        "小学",
        "初中",
        "高中/中专/技校",
        "大学大专",
        "大学本科",
        "硕士",
        "博士及以上",
      ],
    };
  },
  created() {
    // 获取所有的打印量表列表
    // measureGb().then(res=>{
    //   if(res.code==400200){
    //     let arr= res.data.z
    //     // console.log("所有的打印量表列表", arr);
    //     arr.filter(item=>{
    //       let obj={}
    //       // console.log(item.title);
    //       obj.value=`${item.title}.txt`
    //       obj.label=`${item.title}`
    //       this.options.push(obj)
    //     })
    //     console.log("打印列表",this.options);
    //   }
    // })
    // 获取所有部门
    loadDepartmentData().then((res) => {
      this.departmentList = res.data.data;
    });
    // 获取报告类型
    getMeasureClassifyList({ page: 1, size: 99999 }).then((res) => {
      this.evaluateClass = res.data.data;
    });
    // 获取量表名称
    this.loadScaleData();
  },
  methods: {
    cardReading(){
      // this.cardObj.file_name.split(".")[0]
      // console.log(this.cardObj.file_name.split(".")[0]);
      // return
      scanner(this.cardObj).then(res=>{
        // console.log("是否等于00",res.data. error_code=="00");
        if(res.data.error_code=="00" || res.data.data.result){

          this.result=res.data.data.result
          console.log("进入等于00");
          // 发送数据
          measureGbAdd({title:this.cardObj.file_name.split(".")[0],result:this.result}).then(res=>{
            console.log("发送数据",res);
            if(res.code==400200){
              // console.log("打印成功");
              this.$message({
                message: '打印成功',
                type: 'success'
              });
            }
          })
        }else{
          // console.log("111",res.data.data.msg);
          // console.log("2222",res.data);
          this.$message.error(res.data.msg)
          console.log(res.data.msg);
        }
      })
    },
    onSubmit() {
      // if (!this.form.idcard) {
      //   this.$message({
      //     message: "身份证号码不能为空!",
      //     type: "error",
      //   });
      //   return;
      // } else {
      //   let reg = /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/;
      //   if (!reg.test(this.form.idcard)) {
      //     this.$message({
      //       message: "请输入正确的身份证号码!",
      //       type: "error",
      //     });
      //     return;
      //   }
      // }
      // 数据处理
      let data = [];
      let a = ["A", "B", "C", "D", "E", "F", "G", "H"];
      let arr = [];
      let obj = {};
      let answer = [];
      this.answerData.forEach((v, k) => {
        if (typeof v === "string") {
          arr = v.split("-");
          obj = {
            id: arr[1],
            answer: a[parseInt(arr[0])],
          };
          data.push(obj);
          arr = [];
          obj = {};
        } else {
          v.forEach((value) => {
            arr = value.split("-");
            obj = {
              id: arr[1],
            };
            answer.push(a[parseInt(arr[0])]);
          });
          obj["answer"] = answer.join(",");
          data.push(obj);
          arr = [];
          obj = {};
          answer = [];
        }
      });
      let p = {
        user_id: this.form.uId,
        measureId: this.form.measure,
        evaluating_time: this.form.value2 / 1000,
        systemAccess: {
          accessToken: "md5",
        },
        isSave: true,
        answer: data,
        inputs: {},
      };
      console.log(p.evaluating_time);
      // return
      // console.log(this.subject)
      this.subject.forEach((value) => {
        if (value.title && value.text) {
          p.inputs[value.text] = value.title;
        }
      });
      if (this.form.resource == 1) {
        p = {
          account: this.form.account,
          idcard: this.form.idcard,
          password: this.form.password,
          department_id: this.form.department_id,
          measureId: this.form.measure,
          name: this.form.name,
          sex: this.form.sex,
          age: this.form.value1,
          culture: this.form.culture,
          evaluating_time: this.form.value2 / 1000,
          systemAccess: {
            accessToken: "md5",
          },
          isSave: true,
          answer: data,
        };
      }
      
      adminResultsCalculated(p).then((res) => {
        if (res.code == 400200) {
          this.$message({
            showClose: true,
            message: "录入成功！",
            type: "success",
          });
          this.reload();
        }
      });
    },
    // 量表数据请求
    async loadScaleData() {
      var res = await axios.get("/all_measure_classify");
      res.data.data.forEach((v) => {
        v.status_measure.forEach((v) => {
          this.scaleList.push(v);
        });
      });
    },
    // 加载用户信息
    loadUserInfo() {
      let data = {
        page: 1,
        pagesize: 99,
        department_id: this.form.department_id,
      };
      loadUserData(data).then((res) => {
        res.data.forEach((v) => {
          let obj = {
            id: v.id,
            name: v.real_name,
          };
          this.userInfo.push(obj);
        });
      });
    },
    // 监听部门下拉框，并获取用户数据
    upUser() {
      this.userInfo = [];
      this.loadUserInfo();
    },
    // 答题渲染
    async loadAnswer() {
      let id = this.form.measure;
      let r = await axios.get(`/get_measure_by_id?id=${id}`); //220 317 297
      // let r = await axios.get(`/get_measure_by_id?id=297`);
      const reg = /\$\(.+,.+\)/g; // 匹配$()里面的内容
      const reg1 = /\(([^()]+)\)/; // 匹配()里面的内容
      let content = "";
      let arr = [];
      this.subject = r.data.data.questions.map((value, index) => {
        if (reg.test(value.question)) {
          content = value.question.match(reg)[0];
          arr = content.match(reg1)[1].split(",");
          value[arr[0]] = arr[1];
          value["title"] = "";
          value.question = value.question.replace(
            content,
            `<input id="inputs" type="text" style="width:100px;border:none;border-bottom:1px solid;background:rgba(0,0,0,0)" />`
          );
        }
        if (value.extra && value.extra.checkbox) {
          value["isCheck"] = true;
          this.checkList[index] = [];
        }
        return value;
      });
      this.answerData = [];
      this.$nextTick(() => {
        this.handleInput();
      });
    },
    // 防止用户跳题,提前提交
    inspect(i) {
      // 题目第一题的 id
      let one = this.subject[0].id;
      // 点击前的 题目ID
      let a = 0;
      // 当前最新选择的 题目 ID
      if (this.answerData.length > 1) {
        if (this.answerData[this.answerData.length - 2] == undefined) {
          this.$alert("请按照题目顺序依次选择，谢谢", "提示", {
            confirmButtonText: "确定",
            callback: (action) => {},
          });
          // 清除最后一个
          this.answerData.pop();
          this.checkList[i] = [];
          return;
        } else {
          return;
        }
      } else {
        return;
      }
    },
    handleCheckBox(i, index) {
      this.answerData[i] = this.checkList[i];
      this.inspect(i);
    },
    handleInput() {
      // querySelectorAll("input")
      let that = this;
      const inputs = document.querySelectorAll("#inputs");
      for (let i = 0; i < inputs.length; i++) {
        inputs[i].oninput = function () {
          that.subject[
            this.parentNode.getAttribute("index")
          ].title = this.value;
        };
      }
    },
  },
  watch: {
    answerData: function (val) {
      this.inspect();
    },
  },
};
</script>

<style lang="less">
.tc-evaluate_entry {
  .form {
    width: 500px;
    .el-input__inner {
      width: 180px;
      padding-right: 20px;
    }
  }
  .confirm-btn {
    margin-top: 20px;
    width: 300px;
    text-align: center;
  }
  .an-title {
    font-weight: 600;
    color: rgb(18, 54, 42);
  }
  .an-hover {
    padding: 10px 10px;
    border-radius: 10px;
    &:hover {
      transition: all 0.5s;
      background: rgb(199, 239, 246);
    }
  }
}
</style>