// import request from '@/utils/card'

// export function scanner(data) {
//     return request({
//         url: '/scanner',
//         method: 'post',
//         data
//     })
// }
import axios from 'axios'
export function scanner(params) {
    return axios.post('http://127.0.0.1:9777/scanner', params)
}